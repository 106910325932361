import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Flex, Tabs } from 'antd';
import classNames from 'classnames';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { useLayoutModel } from '../../model/LayoutModel';

import { LayoutNavbar } from '../LayoutNavbar';

import styles from './tabs-navbar.module.scss';

export const TabsNavbar = observer(function TabsNavbar() {
	const layoutModel = useLayoutModel();
	const navigate = useNavigate();

	useEffect(() => {
		return reaction(
			() => layoutModel.tabsNavbar.activeNavItem,
			(item) => {
				if (item) {
					navigate(item.to);
				}
			},
		);
	}, [layoutModel.tabsNavbar.activeNavItem, navigate]);

	return (
		<Flex className={styles.navbar}>
			<Dropdown
				trigger={['click']}
				dropdownRender={() => <LayoutNavbar mode="vertical" />}
				placement="bottomLeft"
			>
				<button className={classNames(styles.button, 'dark', 'ant-tabs-css-var')}>
					<MenuOutlined />
				</button>
			</Dropdown>

			<Tabs
				hideAdd
				type="editable-card"
				onChange={layoutModel.tabsNavbar.setSelectedKey}
				activeKey={layoutModel.tabsNavbar.activeKey}
				onEdit={layoutModel.tabsNavbar.handleEdit}
				items={
					layoutModel.tabsNavbar.navItems.length === 1
						? layoutModel.tabsNavbar.navItems.map((v) => {
								return {
									...v,
									closable: false,
								};
							})
						: layoutModel.tabsNavbar.navItems.slice()
				}
				// items={layoutModel.tabsNavbar.navItems.map((v) => {
				// 	return {
				// 		...v,
				// 		label: <Link to={v.to}>{v.label} </Link>,
				// 	};
				// })}
			/>
		</Flex>
	);
});
