import { makeAutoObservable } from 'mobx';

import { Entity } from '@/shared/lib/collections/Entity';

export type UserDto = {
	/** Идентификатор пользователя */
	id: string;
	/** Имя пользователя */
	name: string;
	/** Фамилия пользователя */
	surname: string;
};

export class UserEntity implements Entity<UserDto> {
	constructor(public id: string) {
		makeAutoObservable(this);
	}
	name: string = '';
	surname: string = '';

	get fio() {
		return `${this.surname ? `${this.surname} ` : ''}${this.name}`;
	}

	get permissions() {
		return [];
	}

	updateFromJson(updateDto: UserDto): void {
		this.partialUpdateFromJson(updateDto);
	}

	partialUpdateFromJson(dto: Partial<UserDto>): void {
		Object.assign(this, dto);
	}
}
