import type { AppConfig } from '@/shared/config/AppConfig';

import { ROUTE_PATHS } from '@/shared/config/routePaths';
import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { IntegrationsPage } = await import('./ui/IntegrationsPage');
	return {
		default: IntegrationsPage,
	};
};

export const integrationsPageRoute = (
	appConfig: AppConfig,
	layoutModel: LayoutModel,
): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const { IntegrationsPageModel } = await import('./model/IntegrationsPageModel');
			const SingletonModel = singleton(IntegrationsPageModel);

			const model = new SingletonModel(appConfig);

			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.integrations.path,
		},
	);
