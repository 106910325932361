import { faker } from '@faker-js/faker';
import { v4 } from 'uuid';

import { CompanyDto } from '../model/CompanyEntity';

export function getCompanyDto({
	id = v4(),
	name = 'SECAI',
	bgColor = '#1890FF',
	fullName = 'ООО «СЕКАЙ»',
	logo = '/images/secai.png',
}: Partial<CompanyDto>): CompanyDto {
	return {
		id,
		name,
		bgColor,
		fullName,
		logo,
	};
}
