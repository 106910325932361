import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import { useEffect } from 'react';

import type { AppConfig } from '@/shared/config/AppConfig';

import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel, TabsNavbarItemType } from '@/widgets/layout';

import { ScriptQueryState } from './model/ScriptEditorQueryState';

const importPage = async () => {
	const { PlaybookPage } = await import('./PlaybookPage');
	return {
		default: PlaybookPage,
	};
};

const TabLabel = observer<{ query: ScriptQueryState; removeTab: (scriptId: string) => void }>(
	function TabLabel({ query, removeTab }) {
		const script = query.data;

		useEffect(() => {
			return () => {
				if (query.isError) {
					removeTab(query.data.id);
				}
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<>
				{script.name || (
					<>
						{query.isError ? (
							<WarningOutlined style={{ color: 'var(--ant-color-warning)' }} />
						) : (
							<Spin indicator={<LoadingOutlined spin />} />
						)}
					</>
				)}
			</>
		);
	},
);

export const playbookPageRoute = (
	appConfig: AppConfig,
	layoutModel: LayoutModel,
): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			const scriptID = params.params.scriptID;

			const { PlaybookPageModel } = await import('./model/PlaybookPageModel');
			const SingletonModel = singleton(PlaybookPageModel);
			const model = new SingletonModel(appConfig, layoutModel);

			if (scriptID) {
				const editor = model.addEditor(scriptID);
				layoutModel.setNavbarMode('tabs');

				editor.queryState.script.init();

				const removeTab = (tabKey: string) => {
					layoutModel.tabsNavbar.removeTab(tabKey);
					model.removeEditor(tabKey);
				};
				const dispose = layoutModel.tabsNavbar.addEventListener('remove', (tabKey) => {
					removeTab(tabKey);
					dispose();
				});
				const tab: TabsNavbarItemType = {
					key: scriptID,
					label: <TabLabel query={editor.queryState.script} removeTab={removeTab} />,
					to: ROUTE_PATHS.playbook.buildPath({ scriptID }),
				};
				layoutModel.tabsNavbar.addTab(tab);
				layoutModel.tabsNavbar.setSelectedKey(tab.key);
			}

			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.playbook.path,
		},
	);
