import { QueryClient } from '@tanstack/query-core';
import invariant from 'invariant';
import { action, makeObservable, observable } from 'mobx';
import React, { useContext } from 'react';

import { AuthModel } from '@/features/auth';
import type { RepositoriesManager } from '@/shared/lib/collections/RepositoriesManager';
import { Repository } from '@/shared/lib/collections/Repository';
import { ReactiveTimer } from '@/shared/lib/date/ReactiveTimer';

import { LocaleCode, LocaleConfig } from './localeConfig';

export class AppConfig {
	locale: LocaleConfig;
	reactiveTimer: ReactiveTimer;

	constructor(
		public repositories: RepositoriesManager,
		public auth: AuthModel,
		localeCode: LocaleCode = 'ru',
	) {
		this.locale = new LocaleConfig(localeCode);
		this.reactiveTimer = new ReactiveTimer();
		makeObservable(this, {
			themeMode: observable,
			setThemeMode: action.bound,
			toggleTheme: action.bound,
		});
	}

	queryClient = new QueryClient();

	createQueryKey<IConstructor extends { new (): Repository<any> } = { new (): Repository<any> }>(
		constructor: IConstructor,
		keys: string[] = [],
	) {
		return [this.repositories.id, constructor.name].concat(keys);
	}
	public themeMode: 'dark' | 'light' = 'dark';

	public setThemeMode(mode: 'dark' | 'light') {
		this.themeMode = mode;
	}

	public toggleTheme() {
		this.themeMode === 'dark' ? (this.themeMode = 'light') : (this.themeMode = 'dark');
	}
}

export const AppConfigContext = React.createContext<AppConfig>(null!);

export function useAppConfig() {
	const appConfig = useContext(AppConfigContext);
	invariant(
		!!appConfig,
		'Отсутствует провайдер AppConfigContext! Необходимо зарегистрировать провайдер в корне приложения',
	);

	return appConfig;
}
