import type { AppConfig } from '@/shared/config/AppConfig';

import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { AuditPage } = await import('./ui/AuditPage');
	return {
		default: AuditPage,
	};
};

export const auditPageRoute = (appConfig: AppConfig, layoutModel: LayoutModel): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const { AuditPageModel } = await import('./model/AuditPageModel');
			const SingletonModel = singleton(AuditPageModel);
			const model = new SingletonModel(appConfig);
			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.audit.path,
		},
	);
