import { loadState, saveState } from '@/shared/lib/localStorage';

export const localStorageKeys = {
	integrations: 'demo/integrations/v2',
	deprecatedIntegrations: 'demo/integrations',
	scrips: 'demo/scrips/v2',
	deprecatedScrips: 'demo/scrips',
	users: 'demo/users',
	events: 'demo/events',
	eventsExtras: 'demo/eventsExtras',
	incidents: 'demo/incidents',
	tags: 'demo/tags',
	mitre: 'demo/mitre',
	launches: 'demo/launches',
} as const;

export function withLocalStorage<TResult>(key: string, getData: () => TResult): TResult {
	const initialData = loadState(key);
	if (initialData) return initialData as TResult;

	const data = getData();

	saveState(key, data);
	return data;
}
