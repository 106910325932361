import { StyleProvider } from '@ant-design/cssinjs';

import { ConfigProvider, ThemeConfig, notification, theme } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import { AliasToken } from 'antd/es/theme/internal';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';

import { AntCssKeyContext } from '@/shared/config/AntCssKeyContext';
import { AppConfig, AppConfigContext } from '@/shared/config/AppConfig';
import { getComponentDisplayName } from '@/shared/lib/getComponentDisplayName';

import { CssTokenBridge } from './CssTokenBridge';

const NotificationContext = React.createContext<NotificationInstance>(null!);

export function useNotification() {
	return useContext(NotificationContext);
}

export function configProvider<P extends { [K in keyof P]?: P[K] }>(WrappedComponent: React.FC<P>) {
	const WithConfigProvider = observer<P & { appConfig: AppConfig }>(function WithConfigProvider(
		props: P & { appConfig: AppConfig },
	) {
		const appConfig = props.appConfig;
		const cssKey = appConfig.themeMode;

		const themeConfig = useMemo<ThemeConfig>(() => {
			const colorBgContainer = '#141414';
			const layoutBodyBg = '#1E1E1E';
			const token: Partial<AliasToken> = {
				colorPrimary: '#1890FF',
				colorWarning: '#FAAD14',
				colorSuccess: '#52C41A',
				colorError: '#FF4D4F',
				colorPrimaryHover: '#15417e',
				// colorPrimaryBg: '#051d33',
				// colorPrimaryBgHover: '#072b4c',
				// colorPrimaryBorder: '#093966',
				// colorPrimaryBorderHover: '#3E77AD',
				// colorPrimaryActive: '#1373cc',
				colorLink: '#1890FF',
				// colorLinkHover: '#46a6ff',
				// colorLinkActive: '#1373cc',
				colorBgElevated: '#2C2C2C',
				colorBorderSecondary: '#3E3E3E',
				colorBgSpotlight: '#383838',
				colorBgContainer: colorBgContainer,
				/**@ts-ignore */
				layoutBodyBg: layoutBodyBg,
			};
			return {
				algorithm: (seedToken, mapToken) => {
					const result = theme.darkAlgorithm(seedToken, mapToken);
					return {
						...result,
						...token,
					};
				},
				components: {
					Button: {
						borderRadius: 2,
					},
					Dropdown: {
						colorBgElevated: layoutBodyBg,
						dropdownArrowDistance: 0,
						borderRadiusLG: 4,
					},
					Table: {
						headerBg: '#141414',
						filterDropdownBg: '#2C2C2C',
						rowHoverBg: '#2C2C2C',
						headerBorderRadius: 0,
					},
					Layout: {
						headerBg: layoutBodyBg,
						headerHeight: 52,
						bodyBg: layoutBodyBg,
						colorBgLayout: '#00000',
					},
					Card: {
						paddingLG: 20,
					},
					Modal: {
						paddingLG: 20,
						contentPadding: 16,
						footerMarginTop: 16,
						headerMarginBottom: 16,
						borderRadiusLG: 4,
					},
					Popover: {
						colorBgElevated: layoutBodyBg,
					},
					Menu: {
						horizontalItemSelectedColor: '#1890FF',
					},
					Progress: {
						remainingColor: colorBgContainer,
					},
				},
				token,
				cssVar: { key: cssKey },
				hashed: false,
			};
		}, [cssKey]);

		const [notify, contextHolder] = notification.useNotification();

		return (
			<NotificationContext.Provider value={notify}>
				<AppConfigContext.Provider value={appConfig}>
					<AntCssKeyContext.Provider value={cssKey}>
						<StyleProvider hashPriority="high" autoClear={false}>
							<ConfigProvider theme={themeConfig} locale={appConfig.locale.antLocale}>
								{contextHolder}
								<CssTokenBridge cssKey={cssKey} />
								<WrappedComponent {...props} />
							</ConfigProvider>
						</StyleProvider>
					</AntCssKeyContext.Provider>
				</AppConfigContext.Provider>
			</NotificationContext.Provider>
		);
	});
	WithConfigProvider.displayName = `WithConfigProvider(${getComponentDisplayName(
		WrappedComponent,
	)})`;
	return WithConfigProvider;
}
