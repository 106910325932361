import { Card as AntCard } from 'antd';

import classNames from 'classnames';

import { forwardRef } from 'react';

import type { CardProps as AntCardProps } from 'antd';

import styles from './card.module.scss';

export type CardProps = AntCardProps & {
	rounded?: 'none' | 'lg' | 'sm';
	bg?: 'elevated' | 'spotlight' | 'default';
	hasHeaderDivider?: boolean;
	/** Цвет заголовка */
	titleVariant?: 'tertiary';
	/** Позиция заголовка */
	titlePosition?: 'left' | 'center';
};

export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
	{
		className,
		rounded = 'none',
		bg = 'default',
		bordered = false,
		hasHeaderDivider = true,
		titlePosition,
		titleVariant,
		...props
	},
	ref,
) {
	return (
		<AntCard
			ref={ref}
			className={classNames(styles.container, className, {
				[styles['header-divider']]: hasHeaderDivider,
				[styles.elevated]: bg === 'elevated',
				[styles.spotlight]: bg === 'spotlight',
				[styles.rounded_lg]: rounded === 'lg',
				[styles.rounded_sm]: rounded === 'sm',
				[styles['title-center']]: titlePosition === 'center',
				[styles['title-tertiary']]: titleVariant === 'tertiary',
			})}
			bordered={bordered}
			{...props}
		/>
	);
});

Card.displayName = 'ForwardRef shared/ui/card/(Card)';
