import { v4 } from 'uuid';

import { getUserDto, UserDto } from '@/entities/user';

import { localStorageKeys, withLocalStorage } from './withLocalStorage';

type UserPreset = Partial<UserDto> & { id: string };

function generate() {
	const userPresets: UserPreset[] = [
		((): UserPreset => {
			return {
				id: v4(),
				name: 'Иван',
				surname: 'Иванов',
			};
		})(),
	];

	const users = userPresets.map((v) => getUserDto(v));
	return users;
}

const users = withLocalStorage(localStorageKeys.users, generate);

export function mockRequestUser(): UserDto[] {
	return users;
}

export function getUsers(): UserDto[] {
	return users;
}
