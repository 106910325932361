import { notification, Typography } from 'antd';

import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { LoginForm } from '@/features/auth';
import { ReactComponent as Bg } from '@/shared/assets/bg.svg';
import { Card } from '@/shared/ui/Card';

import { LoginPageModel } from '../../model/LoginPageModel';

import styles from './login-page.module.scss';

type Props = {
	model: LoginPageModel;
};

export const LoginPage = observer<Props>(function LoginPage({ model }) {
	const [api, contextHolder] = notification.useNotification();

	useEffect(() => {
		return reaction(
			() => model.appConfig.auth.loginRequestState.errorData,
			(error) => {
				if (error) {
					api.error({
						message: `Пользователя с таким именем не существует`,
						placement: 'top',
					});
				}
			},
		);
	}, [api, model]);

	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>SECAI AIR</title>
			</Helmet>
			{contextHolder}
			<Bg className={styles.bg} />
			<div className={styles.login}>
				<Card className={styles.card} bg="spotlight" rounded="lg">
					<div className={styles.header}>
						<img src="/images/secai.png" alt="Логотип компании" />
					</div>
					<div className={styles.desc}>
						Система автоматизации реагирования <br />
						на события информационной безопасности
						<br />
						<span className={styles.name}>SECAI AIR</span>
					</div>
					<div className={styles.company}>
						<Typography.Text type="secondary">ООО «СЕКАЙ»</Typography.Text>
					</div>
					<LoginForm
						model={model.loginForm}
						isLoading={model.appConfig.auth.loginRequestState.isLoading}
					/>
				</Card>
			</div>
		</div>
	);
});
