import { Observer } from 'mobx-react-lite';

import { useState } from 'react';

import {
	Outlet,
	RouteObject,
	RouterProvider,
	createBrowserRouter,
	matchPath,
} from 'react-router-dom';

import { v4 } from 'uuid';

import { auditPageRoute } from '@/pages/AuditPage/route';
import { dashboardRoute } from '@/pages/Dashboard/route';
import { eventsPageRoute } from '@/pages/EventsPage/route';
import { incidentPageRoute } from '@/pages/IncidentPage/route';
import { incidentsPageRoute } from '@/pages/IncidentsPage/route';
import { integrationPageRoute } from '@/pages/IntegrationPage/route';
import { integrationsPageRoute } from '@/pages/IntegrationsPage/route';
import { LoginPageWithModel } from '@/pages/LoginPage';
import { notFoundPageRoute } from '@/pages/NotFoundPage/route';
import { playbookPageRoute } from '@/pages/PlaybookPage/route';
import { scriptsPageRoute } from '@/pages/ScriptsPage/route';
import { settingsPageRoute } from '@/pages/SettingsPage/route';
import { useAppConfig } from '@/shared/config/AppConfig';
import { ROUTE_PATHS } from '@/shared/config/routePaths';

import {
	BaseLayout,
	Layout,
	LayoutModel,
	LayoutNavbarMode,
	LayoutProvider,
} from '@/widgets/layout';

function getLayoutMode(): LayoutNavbarMode {
	if (matchPath(ROUTE_PATHS.playbook.path, window.location.pathname)) {
		return 'tabs';
	}
	return 'default';
}

export const Router = function Router() {
	const appConfig = useAppConfig();

	const [layoutModel] = useState(() => {
		const layoutModel = new LayoutModel([], getLayoutMode());
		layoutModel.navbar.setNavigationItems([
			{
				key: v4(),
				route: eventsPageRoute(appConfig, layoutModel),
				to: ROUTE_PATHS.events.buildPath({}),
				label: 'События',
			},
			{
				key: v4(),
				route: incidentsPageRoute(appConfig, layoutModel),
				to: ROUTE_PATHS.incidents.buildPath({}),
				label: 'Инциденты',
			},
			{
				key: v4(),
				route: scriptsPageRoute(appConfig, layoutModel),
				to: ROUTE_PATHS.scripts.buildPath({}),
				label: 'Сценарии',
			},
			{
				key: v4(),
				route: integrationsPageRoute(appConfig, layoutModel),
				to: ROUTE_PATHS.integrations.buildPath({}),
				label: 'Интеграции',
			},
			{
				key: v4(),
				route: auditPageRoute(appConfig, layoutModel),
				to: ROUTE_PATHS.audit.buildPath({}),
				label: 'Аудит',
				disabled: true,
			},
			{
				key: v4(),
				route: settingsPageRoute(appConfig, layoutModel),
				to: ROUTE_PATHS.settings.buildPath({}),
				label: 'Администрирование',
			},
		]);
		return layoutModel;
	});

	const [router] = useState(() => {
		const navItemsRoutes: RouteObject[] = layoutModel.navbar.navItems
			.filter((v) => v.route !== undefined)
			.map((v) => v.route!);
		const router = createBrowserRouter([
			{
				element: (
					<LayoutProvider model={layoutModel}>
						<BaseLayout>
							<Observer>
								{() =>
									appConfig.auth.isAuth ? (
										<Layout>
											<Outlet />
										</Layout>
									) : (
										<LoginPageWithModel appConfig={appConfig} />
									)
								}
							</Observer>
						</BaseLayout>
					</LayoutProvider>
				),
				children: navItemsRoutes.concat([
					notFoundPageRoute,
					playbookPageRoute(appConfig, layoutModel),
					dashboardRoute(appConfig, layoutModel),
					integrationPageRoute(appConfig, layoutModel),
					incidentPageRoute(appConfig, layoutModel),
				]),
			},
			notFoundPageRoute,
		]);
		return router;
	});

	return <RouterProvider router={router} fallbackElement={''} />;
};
