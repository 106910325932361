import {
	BellOutlined,
	QuestionCircleOutlined,
	SearchOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Badge, Dropdown } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useAuthModel } from '@/features/auth';

import styles from './toolbar.module.scss';

export type ToolbarProps = {
	dark?: boolean;
};

export const Toolbar = observer<ToolbarProps>(function Toolbar({ dark }) {
	const authModel = useAuthModel();

	return (
		<div className={classNames(styles.wrapper, { [styles.dark]: dark })}>
			{/* <button className={styles.button}>
				<SearchOutlined />
			</button>
			<button className={styles.button}>
				<QuestionCircleOutlined />
			</button>
			<button className={styles.button}>
				<Badge size="small" color="red" count={5}>
					<BellOutlined />
				</Badge>
			</button> */}
			<Dropdown
				className={styles.user}
				trigger={['click']}
				menu={{
					items: [
						{
							key: 'logout',
							label: 'Выйти',
							onClick: authModel.logout,
						},
					],
				}}
				placement="bottomRight"
			>
				<button className={styles.button}>
					<span className={styles.name}>Иван Иванов</span>
					<UserOutlined className={styles.user_icon} />
				</button>
			</Dropdown>
		</div>
	);
});
