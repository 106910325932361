import { CompanyEntity } from '@/entities/company';
import { getCompanyDto } from '@/entities/company/lib/getCompanyDto';
import type { AppConfig } from '@/shared/config/AppConfig';
import { ROUTE_PATHS } from '@/shared/config/routePaths';
import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { SettingsPage } = await import('./ui/SettingsPage');
	return {
		default: SettingsPage,
	};
};

export const settingsPageRoute = (
	appConfig: AppConfig,
	layoutModel: LayoutModel,
): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const { SettingsPageModel } = await import('./model/SettingsPageModel');
			const SingletonModel = singleton(SettingsPageModel);
			const model = new SingletonModel(new CompanyEntity(''));

			model.company.updateFromJson(getCompanyDto({ id: model.company.id, logo: '' }));

			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.settings.path,
		},
	);
