import { faker } from '@faker-js/faker';
import { v4 } from 'uuid';

import { UserDto } from '../model/UserEntity';

export function getUserDto({
	id = v4(),
	name = faker.person.firstName(),
	surname = faker.person.lastName(),
}: Partial<UserDto>): UserDto {
	return {
		id,
		name,
		surname,
	};
}
