import type { AppConfig } from '@/shared/config/AppConfig';

import { ROUTE_PATHS } from '@/shared/config/routePaths';

import { createDynamicRoute, LazyRouteObject } from '@/shared/lib/router/createDynamicRoute';
import { singleton } from '@/shared/lib/singleton';
import { LayoutModel } from '@/widgets/layout';

const importPage = async () => {
	const { ScriptsPage } = await import('./ui/ScriptsPage');
	return {
		default: ScriptsPage,
	};
};

export const scriptsPageRoute = (appConfig: AppConfig, layoutModel: LayoutModel): LazyRouteObject =>
	createDynamicRoute(
		importPage,
		async (params) => {
			layoutModel.setNavbarMode('default');

			const { ScriptsPageModel } = await import('./model/ScriptsPageModel');
			const SingletonModel = singleton(ScriptsPageModel);
			const model = new SingletonModel(appConfig);
			return {
				model,
			};
		},
		{
			path: ROUTE_PATHS.scripts.path,
		},
	);
