import { route, string } from 'react-router-typesafe-routes/dom';

// export class RoutePath<Params extends string = ''> {
// 	constructor(public path: string) {}

// 	public resolve(params?: Partial<Record<Params, string>>) {
// 		return generatePath(this.path, params);
// 	}
// }

export const ROUTE_PATHS = {
	/** Дашбоард */
	dashboard: route(''),
	/** События */
	events: route('events'),
	/** Инциденты */
	incidents: route('incidents/*'),
	/** Инциденты */
	incident: route('incidents/:incidentID', {
		params: { incidentID: string().defined() },
	}),
	/** Сценарии */
	scripts: route('workflows/*'),
	/** Редактор сценариев */
	playbook: route('workflows/:scriptID', {
		params: { scriptID: string().defined() },
	}),
	/** Интеграции */
	integrations: route('integrations/*'),
	/** Интеграция */
	integration: route('integrations/:integrationID', {
		params: { integrationID: string().defined() },
	}),
	/** Аудит */
	audit: route('audit'),
	/** Администрирование */
	settings: route('settings'),
	/** NOT FOUND PAGE */
	notFound: route('*'),
	/** LOGIN PAGE */
	login: route('*'),
};
