import { Button as AntButton } from 'antd';
import classNames from 'classnames';
import { forwardRef } from 'react';

import type { ButtonProps as AntButtonProps } from 'antd';
import type { ButtonType } from 'antd/lib/button';

import styles from './button.module.scss';

export type ButtonProps = Omit<AntButtonProps, 'danger' | 'type'> & {
	type?: Exclude<ButtonType, 'primary'> | 'fill';
	variant?: 'secondary' | 'default' | 'primary' | 'success' | 'warning' | 'danger';
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
	{ className, variant, type, ...props },
	ref,
) {
	return (
		<AntButton
			ref={ref}
			className={classNames(className, {
				[styles.variant]: !!variant,
				[styles.secondary]: variant === 'secondary',
				[styles.primary]: variant === 'primary',
				[styles.success]: variant === 'success',
				[styles.warning]: variant === 'warning',
				[styles.default]: variant === 'default',
				[styles.danger]: variant === 'danger',
				[styles.fill]: !!variant && type === 'fill',
				[styles.outline]: !!variant && type !== 'fill',
			})}
			type={type === 'fill' ? 'primary' : type}
			{...props}
		/>
	);
});

Button.displayName = 'ForwardRef shared/ui/buttons/(Button)';
